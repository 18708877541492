/* eslint-disable func-names */
import { format } from "date-fns";
import { Form, Formik, ErrorMessage } from "formik";
import React, { useCallback } from "react";
import * as yup from "yup";

import { useApiClient } from "../../../common/apiClient";
import { FormGroup } from "../../../common/forms/FormGroup";
import { Modal } from "../../../common/Modal";

const validationSchema = yup.object().shape({
  minimum_price: yup
    .number()
    .min(0)
    .integer()
    .nullable()
    .label("Minimum price"),
  minimum_nights: yup
    .number()
    .nullable()
    .integer()
    .min(2)
    .max(100)
    .label("Minimum nights per stay"),
  seven_day_discount_percent: yup
    .number()
    .integer()
    .min(0)
    .max(99)
    .label("7-day booking discount %"),
  one_month_discount_percent: yup
    .number()
    .integer()
    .min(0)
    .max(99)
    .label("1-month booking discount %"),
  default_calendar_block: yup.bool(),
  mid_let_opt_in: yup.bool(),
  mid_let_availability_window_start_date: yup.date().nullable(),
  mid_let_availability_window_end_date: yup.date().nullable(),
  mid_let_min_nights: yup
    .number()
    .integer()
    .min(0)
    .nullable()
    .label("Minimum length of stay"),
  mid_let_min_net_return_per_month: yup.number().integer().min(0).nullable()
});

export const HostPreferencesForm = ({
  onClose,
  propertyId,
  currency,
  settings
}) => {
  const apiClient = useApiClient();

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await apiClient.put(
          `/api/properties/${propertyId}/host_preference_settings`,
          { host_preference_setting: values }
        );

        window.location.reload();
      } finally {
        setSubmitting(false);
      }
    },
    [apiClient, propertyId]
  );

  const initialValue = (value) => (Number.isFinite(value) ? value : "");

  const initialValues = {
    minimum_nights: initialValue(settings.minimum_nights),
    price_multiplier: initialValue(settings.price_multiplier),
    seven_day_discount_percent: initialValue(
      settings.seven_day_discount_percent
    ),
    one_month_discount_percent: initialValue(
      settings.one_month_discount_percent
    ),
    minimum_price: initialValue(settings.minimum_price),
    default_calendar_block: settings.default_calendar_block ?? "",
    mid_let_opt_in: settings.mid_let_opt_in ?? "",
    mid_let_availability_window_start_date:
      settings.mid_let_availability_window_start_date,
    mid_let_availability_window_end_date:
      settings.mid_let_availability_window_end_date,
    mid_let_min_nights: initialValue(settings.mid_let_min_nights),
    mid_let_min_net_return_per_month: initialValue(
      settings.mid_let_min_net_return_per_month
    )
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, resetForm }) => (
        <Form className="HostPreferencesForm">
          <Modal onClose={onClose}>
            <Modal.Header>Host Preferences</Modal.Header>
            <Modal.Body>
              <FormGroup
                name="minimum_price"
                label="Minimum price"
                renderAppend={() => currency}
                type="number"
                min="0"
                step="1"
              />

              <ErrorMessage name="minimum_price">
                {(message) => <div className="error">{message}</div>}
              </ErrorMessage>

              <FormGroup
                label="Minimum nights per stay"
                name="minimum_nights"
                type="number"
                min="0"
              />
              <ErrorMessage name="minimum_nights">
                {(message) => <div className="error">{message}</div>}
              </ErrorMessage>

              <FormGroup
                label="7-day booking discount"
                name="seven_day_discount_percent"
                type="number"
                renderAppend={() => "%"}
              />
              <ErrorMessage name="seven_day_discount_percent">
                {(message) => <div className="error">{message}</div>}
              </ErrorMessage>

              <FormGroup
                label="1-month booking discount"
                name="one_month_discount_percent"
                type="number"
                renderAppend={() => "%"}
              />
              <ErrorMessage name="one_month_discount_percent">
                {(message) => <div className="error">{message}</div>}
              </ErrorMessage>

              <FormGroup
                label="Default calendar block"
                name="default_calendar_block"
                type="checkbox"
                checked={values.default_calendar_block}
              />
              <ErrorMessage name="default_calendar_block">
                {(message) => <div className="error">{message}</div>}
              </ErrorMessage>

              <fieldset className="border p-2 mb-4">
                <legend className="w-auto">
                  <h5 className="section-name">Mid-let preferences</h5>
                </legend>

                <FormGroup
                  label="Opt-in"
                  name="mid_let_opt_in"
                  type="checkbox"
                />

                <FormGroup
                  label="Availability window start"
                  name="mid_let_availability_window_start_date"
                  type="datetime-local"
                  min="2019-09-01T00:00"
                  value={
                    values.mid_let_availability_window_start_date
                      ? format(
                          new Date(
                            values.mid_let_availability_window_start_date
                          ),
                          "yyyy-MM-dd'T'HH:mm"
                        )
                      : ""
                  }
                />
                <ErrorMessage name="mid_let_availability_window_start_date">
                  {(message) => <div className="error">{message}</div>}
                </ErrorMessage>

                <FormGroup
                  label="Availability window end"
                  name="mid_let_availability_window_end_date"
                  type="datetime-local"
                  min="2019-09-01T00:00"
                  value={
                    values.mid_let_availability_window_end_date
                      ? format(
                          new Date(values.mid_let_availability_window_end_date),
                          "yyyy-MM-dd'T'HH:mm"
                        )
                      : ""
                  }
                />
                <ErrorMessage name="mid_let_availability_window_end_date">
                  {(message) => <div className="error">{message}</div>}
                </ErrorMessage>

                <FormGroup
                  label="Minimum length of stay (as agreed with host)"
                  name="mid_let_min_nights"
                  type="number"
                  min="0"
                />
                <ErrorMessage name="mid_let_min_nights">
                  {(message) => <div className="error">{message}</div>}
                </ErrorMessage>

                <FormGroup
                  label="Minimum net return per month (as agreed with host)"
                  name="mid_let_min_net_return_per_month"
                  renderAppend={() => currency}
                  type="number"
                  min="0"
                  step="1"
                />
                <ErrorMessage name="mid_let_min_net_return_per_month">
                  {(message) => <div className="error">{message}</div>}
                </ErrorMessage>
              </fieldset>
            </Modal.Body>

            <Modal.Footer>
              <button
                type="button"
                className="btn btn-light"
                onClick={() =>
                  resetForm({
                    values: {
                      minimum_nights: "",
                      price_multiplier: "",
                      seven_day_discount_percent: "",
                      one_month_discount_percent: "",
                      default_calendar_block: false
                    }
                  })
                }
                disabled={isSubmitting}
              >
                Clear
              </button>

              <button
                type="button"
                className="btn btn-light"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="btn btn-submit"
                disabled={isSubmitting}
              >
                Update
              </button>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

HostPreferencesForm.defaultProps = { window };
